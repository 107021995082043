<template>
  <div>
    <b-card-code title="">
      <validation-observer ref="addUser" class="d-flex justify-content-center">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nom et prénom(s)" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Nom et prénom(s)"
                  rules="required"
                >
                  <b-form-input
                    v-model="editedUser.name"
                    id="name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom et prénom(s)"
                    @click="resetInputName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsName }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email" label-for="Email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    v-model="editedUser.email"
                    id="email"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    @click="resetInputEmail"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsEmail }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Mot de passe" label-for="Mot de passe">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    placeholder="Mot de passe"
                    :state="errors.length > 0 ? false : null"
                    :value="password"
                    @click="resetInputPassword"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsPassword }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="1" style="padding-top: 25px">
              <div class="icon-wrapper cursor-pointer" @click="generate()">
                <feather-icon icon="RotateCcwIcon" size="24" />
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group label="Téléphone" label-for="Telephone">
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  rules="required"
                >
                  <b-form-input
                    id="tel"
                    v-model="editedUser.telephone"
                    type="tel"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Téléphone"
                    @click="resetInputTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsTel }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Groupe d'utilisateur"
                label-for="Groupe d'utilisateur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Groupe d'utilisateur"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-select
                      id="groupe"
                      v-model="editedUser.role"
                      :options="groupesListe"
                      value-field="libelle"
                      text-field="libelle"
                      placeholder="Groupe d'utilisateur"
                      :state="errors.length > 0 ? false : null"
                      @click="resetInputGroupeUtilisateur"
                      @change="checkSelectedGroup(editedUser.role)"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errorsGroupeUtilisateur
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                v-if="hasResources"
                label="Ressources autorisées"
                label-for="Ressources autorisées"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ressources autorisées"
                  rules=""
                >
                  <div class="form-label-group">
                    <v-select
                      id="ressources"
                      v-model="ressourceModel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="ressources"
                      multiple
                      label="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ressources autorisées"
                      @click="resetInputRessources"
                      :closeOnSelect="false"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsRessources }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                v-if="hasResources"
                label="Comptes autorisés"
                label-for="Comptes autorisés"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comptes autorisés"
                  rules=""
                >
                  <div class="form-label-group">
                    <v-select
                      id="comptes"
                      v-model="accountModel"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="accounts"
                      multiple
                      label="libelle"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Comptes autorisés"
                      @click="resetInputComptes"
                      :closeOnSelect="false"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsComptes }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col class="mt-2">
              <b-button
                type="reset"
                variant="outline-secondary"
                class="mr-1"
                @click="resetForm()"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                type="submit"
                variant="primary"
                @click.prevent="saveUser()"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BIconArrowClockwise,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";

export default {
  components: {
    VueSweetalert2,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BIconArrowClockwise,
    BFormInput,
    BFormGroup,
    vSelect,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  data() {
    return {
      Deselect: {
        render: (createElement) => createElement("span", "x"),
      },
      OpenIndicator: {
        render: (createElement) => createElement("span", ""),
      },
      etatButton: true,
      dir: false,
      groupesListe: [],
      editedUser: {},
      hasResources: false,
      alphaDash,
      selectedAccountType: "",
      ressources: [],
      ressourceModel: [],
      accounts: [],
      accountModel: [],
      password: "",
      isOperating: false,
      errorsName: null,
      errorsEmail: null,
      errorsPassword: null,
      errorsTel: null,
      errorsGroupeUtilisateur: null,
      errorsRessources: null,
      errorsComptes: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
  },
  created() {},
  mounted() {
    this.getGroups();
    this.getComptes();
  },
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getGroups() {
      this.$http
        .get("/user-groupes")
        .then((response) => {
          this.groupesListe = response.data.data;
        })
        .then(() => {});
    },
    checkSelectedGroup(groupSelected) {
      let data = this.groupesListe.find(
        ({ libelle }) => libelle == groupSelected
      );

      if (data) {
        this.selectedAccountType = data.libelle;
        let table = ["Chargeur", "Associe", "Importateur"];
        if (table.includes(this.selectedAccountType)) {
          this.hasResources = true;
          this.getDatas(this.selectedAccountType);
        } else {
          this.hasResources = false;
        }
      }
    },
    getDatas(role) {
      let axiosValue = "";
      switch (role) {
        case "Associe":
          axiosValue = "associes-acheteurs";
          break;
        case "Chargeur":
          axiosValue = "chargeurs";
          break;
        case "Importateur":
          axiosValue = "importateurs";
          break;

        default:
          break;
      }
      this.ressources = [];
      this.editedUser.resource = [];
      this.ressourceModel = [];
      this.accountModel = [];
      this.editedUser.accounts = [];
      this.$http.get("/" + axiosValue).then((response) => {
        this.ressources = response.data.data;
      });
    },
    getComptes() {
      this.$http.get("/comptes").then((response) => {
        this.accounts = response.data.data;
      });
    },
    generate() {
      let size = 8;
      let characters = "'a-z,A-Z,0-9,#'";
      let CharacterSet = "";
      let password = "";
      CharacterSet +=
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      for (let i = 0; i < size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
    saveUser() {
      this.$refs.addUser.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.isOperating = true;
          this.editedUser.password = this.password;
          this.editedUser.password_confirmation = this.password;

          if (this.ressourceModel) {
            this.editedUser.ressource = this.ressourceModel;
          }

          if (this.accountModel) {
            this.editedUser.accounts = this.accountModel;
          }

          this.$http
            .post("/register", this.editedUser)
            .then((result) => {
              // this.isOperating = false
              if (result.data.success == false) {
                this.etatButton = true;
                let error = result.data.errors;
                error = error[Object.keys(error)[0]][0];

                this.showToast(error, "danger");
              } else {
                this.resetForm();
                this.etatButton = true;
                this.editedUser = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.showToast(
                  "Nouvel utilisateur enregistré avec succès",
                  "success"
                );
                this.$router.go(-1);
                // this.$router.push(
                // 	{
                // 		name: 'utilisateurs-create',
                // 	}
                // )
              }
            })
            .catch((e) => {
              this.etatButton = true;

              let err = e.response.data.errors;

              if (err) {
                if (err.name) {
                  this.errorsName = err.name[0];
                }

                if (err.email) {
                  this.errorsEmail = err.email[0];
                }

                if (err.password) {
                  this.errorsPassword = err.password[0];
                }

                if (err.telephone) {
                  this.errorsTel = err.telephone[0];
                }

                if (err.groupe) {
                  this.errorsGroupeUtilisateur = err.groupe[0];
                }

                if (err.ressource) {
                  this.errorsRessources = err.ressource[0];
                }

                if (err.Comptes) {
                  this.errorsComptes = err.Comptes[0];
                }
              } else if (e.response.data.exception) {
                this.resetForm();
                this.editedUser = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.showToast(
                  "Nouvel utilisateur enregistré avec succès",
                  "success"
                );
                this.$router.go(-1);
              }
            });
        } else {
          console.log(" validation du formulaire false");
        }
      });
    },
    resetForm() {
      this.editedUser.name = "";
      this.editedUser.email = "";
      this.editedUser.telephone = "";
      this.ressourceModel = "";
      this.password = "";
      this.editedUser.role = "";
      this.accountModel = "";
      this.resetInputName();
      this.resetInputEmail();
      this.resetInputPassword();
      this.resetInputTel();
      this.resetInputGroupeUtilisateur();
      this.resetInputRessources();
      this.resetInputComptes();
    },
    resetInputName() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },
    resetInputEmail() {
      if (this.errorsEmail) {
        this.errorsEmail = null;
      }
    },
    resetInputPassword() {
      if (this.errorsPassword) {
        this.errorsPassword = null;
      }
    },
    resetInputTel() {
      if (this.errorsTel) {
        this.errorsTel = null;
      }
    },
    resetInputGroupeUtilisateur() {
      if (this.errorsGroupeUtilisateur) {
        this.errorsGroupeUtilisateur = null;
      }
    },
    resetInputRessources() {
      if (this.errorsRessources) {
        this.errorsRessources = null;
      }
    },
    resetInputComptes() {
      if (this.errorsComptes) {
        this.errorsComptes = null;
      }
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.vs__selected .vs__deselect {
  fill: seashell;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
